import React from "react";
import SEO from "../components/SEO";
import Section from "../components/Section";
import Typography from "../components/Typography";

const NotFoundPage = () => (
  <Section shrinkTop>
    <SEO title="404: Not found" />
    <Typography variant="h1">Not found</Typography>
  </Section>
);

export default NotFoundPage;
